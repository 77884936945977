@import ./index

.fileInput
  display: none

.fileTypesInfo
  font-size: 11px
  text-align: right
  color: $aurecon--warmgrey--500

.attachments
  display: flex
  align-items: flex-start
  margin: 16px 0

  > div:first-child
    margin-right: 12px
    display: flex
    align-items: center

    i
      top: 0
      line-height: normal
      font-size: 16px

    span
      font-size: 12px

  > div:last-child
    flex: 1

    button
      display: block
      height: auto
      padding: 0
      text-decoration: underline
      font-weight: normal
      margin-bottom: 8px
      color: $aurecon--warmgrey--700
      font-size: 14px
      line-height: 1
      max-width: 250px
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

      &:last-child
        margin-bottom: 0

      &:active
        border: 1px white solid


.files
  margin-top: -5px
  width: 100%

  td
    padding: 4px
    color: $aurecon--warmgrey--700
    line-height: 1
    max-width: 257px
    overflow: hidden
    text-overflow: ellipsis

    button
      background-color: transparent
      border: 0
      padding: 0
      margin: 0 !important
      cursor: pointer
      outline: none

    i
      font-size: 16px
      color: $primary--colour--main
      top: -1px

.progress
  height: 4px
  background: #eee
  margin-top: 4px
  position: relative

  > div
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 100%
    background-color: $primary--colour--main
    transform-origin: left
    transform: scaleX(0)

.uploadProgressDialogHolder
  position: fixed
  top: 24px
  right: 24px

.uploadProgressDialog
  background-color: white
  width: 400px
  padding: 20px
  margin-bottom: 20px
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25)
  border: 1px $aurecon--blue--500 solid
  border-left: 4px $aurecon--blue--500 solid

  > div:first-child
    display: flex
    align-items: center
    margin-bottom: 10px

    i
      top: 0
      margin-right: 10px
    
    h4
      margin: 0
      font-weight: bold

  p
    font-size: 14px
    margin: 0 0 4px
