.content
  padding: 36px 50px
  display: flex
  flex-direction: column
  height: calc(100% - 63px)
  overflow: auto

  > h3
    margin: 0 0 10px 0

  div:global(.switcher-wrapper)
    margin-bottom: 10px
    flex: 0

.chartHolder
  flex: 1
  overflow: hidden
  min-height: 240px
