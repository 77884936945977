@import './index.scss'

.designOptionsWrapper
  position: relative
  overflow: auto
  height: calc(100% - 63px)

.contents
  position: relative
  padding: 16px 50px 36px

.toggleLabel
  vertical-align: text-top
  height: 16px
  width: 16px
  display: inline-block
  margin-right: 8px

.divider
  border-top: 1px solid $primary--ui
  margin: 32px 0
  height: 1px
  width: 100%

.layers
  display: flex
  width: 100%
  flex-wrap: wrap

  > span
    margin-right: 20px