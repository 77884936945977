@import ./index
  
.overlay
  height: 100vh
  width: 100vw
  position: fixed
  z-index: 1000
  left: 0
  top: 0
  background-color: rgba(0,0,0, 0.9)
  overflow-x: hidden
  transition: 0.5s

  .closeButton
    position: absolute
    top: 32px
    right: 32px

  .imageWrapper
    position: relative
    width: 75%
    height: 75%
    margin: 5% auto

    img
      width: 100%
      height: 100%
      object-fit: contain