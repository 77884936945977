@import ./index
  
.page
  width: 100%
  min-height: 100vh
  overflow: hidden
  position: relative
  padding-bottom: 24px

.bg
  svg
    z-index: -1000
    pointer-events: none
    position: absolute
    width: 100%
    height: 100%
    opacity: 0.1
    fill: $aurecon--warmgrey--200
    z-index: -100