@import ./index

.measurement
  p
    font-size: 12px

.title
  margin: 0 0 20px 0

.button
  display: flex
  align-items: center
  width: 100%
  height: 40px !important
  
  svg
    margin-right: 12px

  &.active:global(.is-default):enabled:not(.is-loading),
  &.active:global(.is-default):focus:enabled:not(.is-loading)
    background-color: $aurecon--warmgrey--700 !important
    color: white !important
    border: 0

    svg
      path
        stroke: white
        
      circle
        fill: white

.tool
  display: flex
  flex-wrap: wrap

  button:first-child
    flex: 1

  button:nth-child(2)
    margin-left: 8px

  .prev
    width: 100%
    display: flex
    margin-bottom: 20px
    flex-wrap: wrap

    > div
      width: 100%
      display: flex
      margin-top: 8px
      align-items: center
      padding: 8px
      border: 2px $aurecon--warmgrey--050 solid

      span
        font-family: Arial, sans-serif
        font-size: 14px
        flex: 1

    .close:global(.is-icon):enabled:not(.is-loading),
    .visibility:global(.is-icon):enabled:not(.is-loading),
    .visibilityOff:global(.is-icon):enabled:not(.is-loading)
      background-color: white !important
      border: 0 !important
      color: $primary--colour--main
      width: 16px !important
      height: 16px !important
      margin: 0
      
      i
        font-size: 16px !important
        display: block
        font-weight: bold

    .visibility:global(.is-icon):enabled:not(.is-loading),
    .visibilityOff:global(.is-icon):enabled:not(.is-loading)
      margin-right: 8px

    .visibilityOff:global(.is-icon):enabled:not(.is-loading)
      i
        color: gray

  .prevArea
    display: flex
    flex-flow: column
    flex: 1

    span:first-child
      margin-bottom: 8px
