@import './index'

.topMenuWrapper
  font-family: Arial, Helvetica, sans-serif
  color: $primary--text--colour--light
  font-size: 14px
  position: fixed
  top: 0
  left: 0
  width: 200px
  background-color: #fff
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3)
  -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3)
  box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.3)
  z-index: 220

  .topMenu
    padding: 12px 12px 6px 16px
    cursor: pointer

  .menuIcon
    margin-left: 98px
    top: -7px !important
    height: 14px
    line-height: 0.5

  .menuDivider
    margin: 9px 0
    border-bottom: 2px solid #F2F2ED

  .menuItemActive
    padding: 8px 16px
    color: $primary--colour--main

  .menuItemSmallActive
    padding: 4px 16px
    color: $primary--colour--main
    font-size: 12px

  .menuItem
    box-sizing: border-box
    cursor: pointer

    &:hover
      background-color: rgba(25, 22, 192, 0.15)

    a, button
      cursor: pointer
      width: calc(100% - 12px)
      font-size: 14px
      text-decoration: none
      text-align: left
      color: $primary--text--colour--light
      padding: 8px 0 8px 16px
      border: none
      background: none

      &:focus
        outline: none

  .menuSmall
    background-color: $primary--light
    font-size: 12px
    padding: 6px 0
    margin-top: 12px

  .menuItemSmall
    background-color: $primary--light
    box-sizing: border-box
    cursor: pointer

    &:hover
      background-color: rgba(25, 22, 192, 0.15)

    a, button
      cursor: pointer
      width: calc(100% - 12px)
      font-size: 12px
      text-decoration: none
      text-align: left
      color: $primary--text--colour--light
      padding: 6px 16px
      border: none
      background: none

      &:focus
        outline: none

  .menuFooter
    background-color: $primary--light
    font-size: 12px
    padding: 12px 16px