@import ./index

.mapLegend
  position: absolute
  bottom: 50px
  left: 50px
  width: 250px
  padding: 12px 20px
  background-color: white
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3)
  overflow-y: auto

.legendHeader
  font-family: Arial, sans-serif
  font-size: 14px
  font-weight: bold
  margin: 0

.legendEntry
  font-family: Arial, sans-serif
  font-size: 12px
  line-height: 1.5
  margin: 8px 0 8px 8px
  display: flex
  overflow-wrap: anywhere

.legendSubEntry
  font-family: Arial, sans-serif
  font-size: 12px
  line-height: 1.5
  margin: 8px 0 8px 24px
  display: flex
  overflow-wrap: anywhere

.legendFill
  min-width: 16px
  height: 16px
  border-width: 1px
  border-style: solid
  margin-right: 8px
  vertical-align: text-top
  align-self: center

.legendCircle
  min-width: 16px
  height: 16px
  border-radius: 50%
  border-width: 1px
  border-style: solid
  margin-right: 8px
  vertical-align: text-top
  align-self: center

.legendLine
  min-width: 16px
  height: 4px
  margin-right: 8px
  align-self: center

.heatmapLegend
  margin: 8px 0 8px 24px
  padding: 2px 0px
  display: flex
  align-items: center
  font-size: 12px

.heatmapGradient
  width: 100%
  height: 5px

.heatmapLeftLabel
  padding: 0 5px 0 0

.heatmapRightLabel
  padding: 0 0 0 5px