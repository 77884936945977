.modalDivider
  position: relative
  border-bottom: 1px solid gray
  margin: 16px -24px 24px

.list
  font-family: Arial, Helvetica, sans-serif
  font-size: 16px

  > li
    font-weight: bold
    margin-top: 3em

.tableList
  li
    font-family: Arial, Helvetica, sans-serif
    font-size: 14px