@import ./index
  
.existingComments
  flex: 1
  overflow: auto
  margin-bottom: 10px
  padding-right: 10px

.commentEditWrapper
  text-align: left

  > p:global(.h2)
    margin-top: 0

  .dropdown
    margin: 1em 0

.dateIcon
  margin-top: 0
  font-size: 12px

  i
    font-size: 20px
    top: 0
    margin-right: 8px

  svg
    margin-right: 8px
    vertical-align: middle
    
    > path
      fill: #52534B

.title
  display: flex
  align-items: center

  h2
    flex: 1
    margin: 0
    font-size: 20px

  span
    flex: 0

.smallText
  font-size: 12px

.drawerActions
  display: flex
  flex-direction: row-reverse

  > button
    margin-left: 20px

.drawerActionsComment
  display: flex
  flex-direction: row-reverse
  justify-content: space-between
  
  > button:nth-child(2)
    margin-left: auto
    margin-right: 20px


.titleInput
  margin: 10px 0

.categoryLeadName
  margin: 10px 0

.replyComment
  padding-left: 18px
  border-left: 2px solid #D6D7D3

.medText
  font-size: 14px

.reply
  border-top: 1px #CECECE solid
  margin-top: auto

  > h3
    margin: 20px 0 10px

  > p
    font-size: 14px
    margin: 10px 0

  .dropdown
    margin: 1em 0

  .drawerActions
    margin-top: 10px

  .replyBox
    height: 146px
    overflow: auto

  .replyInfo
    color: $aurecon--warmgrey--800

.switcher
  display: flex
  min-height: 40px
  margin-bottom: 10px

  button
    background-color: white
    padding: 0
    border: 0
    border-bottom: 2px white solid
    flex: 1
    color: $aurecon--warmgrey--700
    cursor: pointer
    outline: none

    &.active
      color: $primary--colour--main
      font-weight: 700
      border-bottom: 2px $primary--colour--main solid
      cursor: default

    &:disabled
      cursor: default
      color: $aurecon--warmgrey--100

.fieldAboveLabel
  font-family: Arial
  font-size: 14px
  margin-bottom: 8px

.fieldInlineLabel
  font-family: Arial
  font-size: 14px

.fieldValue
  font-family: Arial
  font-size: 14px
  margin-bottom: 16px

.thumbnailWrapper
  overflow-x: auto
  overflow-y: hidden
  white-space: nowrap

.thumbnail
  height: 150px
  margin-right: 16px
  cursor: pointer