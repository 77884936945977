@import './index.scss'

.gisWrapper
  position: relative
  overflow: auto
  height: calc(100% - 63px)

.contents
  position: relative
  padding: 36px 50px

.title
  margin: 0

.divider
  border-top: 1px solid $primary--ui
  margin-top: 32px
  height: 1px
  width: 100%

button.group:global(.is-default),
button.group:global(.is-default):hover
  border: 1px $secondary--colour--background solid !important
  border-left: 0 !important

  &:first-child
    border-left: 1px $secondary--colour--background solid !important

button.group:enabled:global(.is-default):not(.is-loading),
button.group:enabled:global(.is-default):not(.is-loading):hover,
button.group:enabled:global(.is-default):not(.is-loading):active
  &.active
    background-color: $aurecon--grey--600 !important
    color: white !important

.layers
  display: flex
  width: 100%
  flex-wrap: wrap
  margin-top: 32px
  margin-bottom: -20px

.toggle
  color: $primary--colour--main
  align-items: center
  margin-left: 16px

  span:global(.toggle-label)
    font-weight: normal
    margin-left: 0 !important
