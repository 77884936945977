.content
  margin-top: 80px
  width: 100%

  h1
    width: 100%
    text-align: center
    margin-top: 60px
    margin-bottom: 20px

.back-to-top
  width: 100%
  margin-bottom: 60px
  button
    float: right