@import ./index

.filterWrapper
  padding: 0 16px
  background-color: #F2F2ED
  display: flex
  align-items: center
  width: 100%
  margin-right: 4px

  > p
    flex: 0
    margin: 0
    margin-right: 24px
    font-size: 14px

  > div
    flex: 1
    margin-right: 12px

  .clearFilters
    flex: 1
    text-align: right
    margin-right: 0

    > button
      border: none
      cursor: pointer
      font-size: 14px

      &:hover
        color: $primary--colour--main

.dropdownItem
  font-size: 14px
  white-space: nowrap
  margin: 0

  > span
    font-weight: bold

.activeFilterSelection
  font-size: 14px
  color: $primary--colour--main

  &:hover
    color: white

.inactiveFilterSelection
  font-size: 14px

.datePickerWrapper
  position: relative
  display: block
  width: 250px
  flex: none !important

.datePickerOverlay
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background-color: transparent

.datePickerLayer
  position: absolute
  left: 0
  bottom: -200px
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3)
  background-color: white

.datePickercloseButton
  padding: 16px 6px 6px
  text-align: end

  > i
    color: $primary--colour--main
    cursor: pointer
    z-index: 1

    &:hover
      color: black