@import ./index

.archiveModal
    font-family: Arial
    h2
        font-size: 32px !important
        margin: 0 !important
        line-height: 48px !important
    p
        font-size: 14px !important

.archiveModalButtons
    display: flex
    justify-content: flex-end
    border-top: 1px solid $align-divider-colour
    padding-top: 26px
    margin-top: 20px

    > button
        margin-left: 20px !important

.closeButtonArchiveModal
    cursor: pointer
    text-align: end
    height: 50px