@import ./index

.mapContainer
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.controls
  position: absolute
  top: 20px
  right: 20px
  transition: transform 0.6s ease-in-out
  transform: translateX(0vw)
  // TODO Anchor this to the sidebar so it moves automatically

  &.drawerOpen
    transform: translateX(-368px)

  > button
    background-color: white
    border-radius: 100%
    border: 0
    width: 38px
    height: 38px
    display: flex
    justify-content: center
    align-items: center
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 1px
    cursor: pointer
    outline: none
    margin-bottom: 16px

    &:last-child
      margin-bottom: 0

    img,
    svg
      width: 28px
      height: 28px
      display: block
      user-select: none

button.satellite
  background-color: $primary--colour--main

.zoom
  position: relative
  background-color: white
  border-radius: 24px
  width: 38px
  height: 214px
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 1px
  padding: 20px 0
  margin-bottom: 20px

  input
    appearance: none
    -webkit-appearance: none
    transform: rotate(270deg) translate(-80px, -48px)
    background: rgb(238, 238, 238)
    border-radius: 4px
    height: 10px
    outline: none
    cursor: pointer

    &::-webkit-slider-thumb
      appearance: none
      -webkit-appearance: none
      background-color: #52534B
      box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 1px
      border-radius: 100%
      width: 16px
      height: 16px

  button
    background: transparent
    border: 0
    padding: 0
    width: 16px
    height: 16px
    position: absolute
    left: 11px
    outline: none
    cursor: pointer

    &:first-of-type
      top: 14px

    &:last-of-type
      bottom: 14px

    svg
      display: block
      width: 16px
      height: 16px

button.commentsButton,
button.measureButton
  &.active
    background-color: $primary--colour--main

    svg path
    fill: white

  svg
    display: block
    width: 20px
    height: 20px

.streetViewMarker
  pointer-events: none

  svg
    fill: $primary--colour--main
    width: 32px
    height: 32px
    display: block
    transform: translateY(-50%)

.projectButton
  i
    top: 0
    color: $aurecon--warmgrey--700

.mapboxCommentMarker
  pointer-events: none

  &.archived
    opacity: 35%

.commentMarker
  cursor: pointer
  height: 30px
  pointer-events: auto

.selectedMarker
  cursor: pointer
  height: 40px
  width: 40px

  circle
    fill: $primary--colour--main--dark

.newComment
  cursor: pointer
  height: 40px
  width: 40px
  pointer-events: auto

  circle
    fill: $primary--colour--main--dark

.markersVisible
  fill: red

$colours: $pill--colour--1, $pill--colour--2, $pill--colour--3, $pill--colour--4, $pill--colour--5, $pill--colour--6, $pill--colour--7, $pill--colour--8, $pill--colour--9, $pill--colour--10, $pill--colour--11, $pill--colour--12, $pill--colour--13, $pill--colour--14, $pill--colour--14, $pill--colour--16

@for $i from 1 through 16
  .is-colour-#{$i}
    circle
      fill: nth($colours, $i)

.measurePopup

  div:global(.mapboxgl-popup-content)
    padding: 10px

    p
      margin: 0
      padding: 0 !important

.propertiesPopup
  pointer-events: auto !important
  z-index: 1

  div:global(.mapboxgl-popup-content)
    padding-top: 20px

.sources
  width: 300px
  max-height: 400px
  overflow: auto
  padding: 4px

  > div
    margin-bottom: 10px

    &:last-child
      margin-bottom: 0

    h4
      font-weight: bold
      margin: 0 0 8px
      color: $aurecon--warmgrey--800
      font-size: 14px

    table
      width: 100%
      border-collapse: collapse
      table-layout: fixed

    tr:nth-child(2n)
      background-color: $aurecon--warmgrey--050

    td
      font-family: Arial, Helvetica, sans-serif
      text-align: left
      font-size: 12px
      padding: 4px
      word-break: break-all

      &:first-child
        width: 30%

      &:last-child
        width: 70%

.closeButton
  position: absolute !important
  top: 2px
  right: 2px
  padding: 0 !important
  border: 0 !important
  width: 20px !important
  height: 20px !important

  i
    display: block
    margin-right: 0
