@import ./index

.comments
  position: relative
  height: calc(100% - 63px)

  .containerCommentsActions
    margin-bottom: 20px

.sticky
  padding: 36px 50px 20px
  position: relative
  background-color: white
  z-index: 1

  h3
    margin: 0

.title
  display: flex
  align-items: center
  width: 100%
  margin-bottom: 20px

  h3
    width: 70%

  .radioButton
    display: flex
    width: 30%
    justify-content: flex-end

.controls
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%

  > *:global(.switcher-wrapper)
    flex: none

.contents
  position: relative
  padding: 0px 50px 36px
  overflow: auto
  height: calc(100% - 162px)

  *:global(.is-header)
    td
      white-space: nowrap

  td:global(.is-sortable-header)
    user-select: none

  td:global(.table-cell)
    &:nth-child(4),
    &:nth-child(7)
      min-width: 140px

    &:nth-child(6)
      max-width: 600px
    
    &:last-child
      width: 115px
      white-space: nowrap

.pagination
  display: flex
  justify-content: center
  align-items: flex-start
  margin-top: 32px

tr.active
  td
    font-weight: bold

    &:first-child
      color: $primary--colour--main

      svg
        margin-left: 8px

tr.active, tr.reply
  td:first-child
    border-left: 2px solid $primary--colour--main !important

tr.archived
  td:not(:last-child)
    opacity: 35%
  
tr.archived:nth-child(odd) td:last-child 
  background-color: rgba($aurecon--white, 0.35) !important

tr.archived:nth-child(even) td:last-child 
  background-color: rgba($aurecon--warmgrey--050, 0.35) !important

tr.archivedReply 
  td
    opacity: 35%
  
@media screen and (max-width: 576px)
  .title
      display: flex
      flex-direction: column



