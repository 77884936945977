@import './index.scss'

.card
  background: $aurecon--warmgrey--050
  padding: 20px 20px 5px 20px
  width: 100%
  margin-bottom: 3px
  box-shadow: none
  min-height: 230px
  border: none
  flex: 1

  h4
    margin-top: 0
    font-size: 18px
    color: $aurecon--warmgrey--700

  &.activeCard
    background: white
    border: 1px solid $primary--colour--main

    h4
      color: $primary--colour--main

  > *
    padding-left: 8px
    padding-right: 8px

.open
  padding: 4px
  background-color: transparent
  border: 0
  cursor: pointer
  outline: none
  float: right
  margin-left: 4px
  color: $aurecon--warmgrey--700

.edit
  width: 100%
  border: 0
  outline: none
  cursor: pointer
  background: $aurecon--warmgrey--050
  font-size: 14px
  min-height: 40px
  height: 40px
  color: $aurecon--warmgrey--700
  
  &:hover
    background: $aurecon--warmgrey--100

.categories,
.admins
  display: flex
  flex-flow: row wrap
  align-items: center
  margin-bottom: -8px

  > span
    font-family: Arial, sans-serif
    font-size: 14px
    margin-right: 8px
    margin-bottom: 6px

.admins
  margin-bottom: 8px

.pill
  overflow: hidden

  > span
    overflow: hidden
    max-width: 100%
    text-overflow: ellipsis
    white-space: nowrap
