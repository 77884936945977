@import '../../node_modules/@aurecon-creative-technologies/styleguide/src/scss/_component-vars.scss'

.frame
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 210
  background-color: white
  height: 400px
  transition: height 300ms ease-out

.top
  width: 100%
  height: 55px
  background-color: $aurecon--warmgrey--700
  display: flex
  padding: 0 50px
  margin-bottom: 8px

  *:global(.button.is-default),
  *:global(.button.is-icon.is-medium)
    height: 100%
    background-color: $aurecon--warmgrey--700
    border: 1px solid $aurecon--warmgrey--700
    color: white

    &:hover:enabled:not(.active),
    &:focus:enabled:not(.active)
      background-color: $aurecon--warmgrey--050 !important
      border: 1px solid $aurecon--warmgrey--050

      i
        color: #263238

      &.active:enabled
        background-color: white !important
        color: #263238 !important
        border: 1px solid white !important

    &.active:enabled:not(:global(.is-loading))
      background-color: white !important
      color: #263238 !important
      border: 1px solid white !important

      span
        font-weight: 700

  .expanded
    transform: rotate(180deg)

.container
  height: calc(100% - 55px)
  overflow: hidden
  
  > div
    width: 100%
    max-height: 100%
    overflow: auto

.button
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  width: 56px
  border: 0
  padding: 0
  background-color: $aurecon--warmgrey--700
  outline: none

  &:hover:enabled,
  &:focus:enabled
    background-color: $aurecon--warmgrey--050

    svg
      fill: #263238

  svg
    display: block
    width: 22px
    height: 22px

  &.drag
    margin-left: auto
    cursor: ns-resize

.button:disabled
  cursor: default !important
  opacity: 0.3

@media screen and ( max-width: 920px )
  .top

    *:global(.button.is-default),
      i
        display: none
