@import ./index

.streetViewPreview
  position: fixed
  z-index: 10
  top: 24px
  right: 90px
  width: 360px
  padding: 16px
  background-color: white
  border-radius: 4px
  box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 1px
  flex-flow: row nowrap
  align-items: center
  display: none
  transition: transform 0.6s ease-in-out
  transform: translateX(0vw)

  &.drawerOpen
    transform: translateX(-368px)

  &.active
    display: flex

  img
    width: 120px
    min-height: 10px
    margin-right: 8px
    cursor: pointer

  p
    line-height: normal
    font-size: 12px
    margin: 0

    &:first-child
      margin-bottom: 8px
      font-weight: bold

  .closeButton
    position: absolute
    top: 2px
    right: 2px
    padding: 0
    border: 0 !important
    width: 20px
    height: 20px

    i
      display: block
      margin-right: 0

.streetViewPopup
  position: fixed
  left: 0
  top: 0
  bottom: 0
  right: 0
  z-index: 250
  background-color: rgba(0, 0, 0, 0.5)
  padding: 48px

  button.closeButton
    border: 0px
    cursor: pointer
    width: 32px !important
    height: 32px !important
    position: absolute
    top: 8px
    right: 8px

    &:global(.is-default.is-icon):enabled:not(:global(is-loading))
      i
        color: $aurecon--warmgrey--800

      &:hover
        background-color: $aurecon--warmgrey--700 !important
        border: 1px solid $aurecon--warmgrey--700

        i
          color: $aurecon--warmgrey--050

.svCloseIcon
  position: absolute !important
  right: 12px !important
  top: 17px !important
  color: $primary--colour--main
  cursor: pointer

  &:hover
    color: black