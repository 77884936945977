.mainProjectWrapper
  height: calc(100vh - 63px)
  position: relative

  div:global(.drawer-wrapper)
    width: 368px

  .testTemp
    position: fixed
    top: 20vh
    padding: 20px
    text-align: center