.toggle
  overflow-wrap: anywhere

  &:not(.short)
    width: 300px
    margin: 0 20px 20px 0

    span:global(.toggle-wrapper .toggle)
      margin-top: 2px

  &.short
    margin-right: 20px

  span:global(.toggle-wrapper .toggle)
    align-items: flex-start

  span:global(.toggle-wrapper .toggle-label)
    cursor: pointer
    display: flex

.square
  height: 16px
  min-width: 16px
  display: inline-block
  margin-top: 4px
  margin-right: 8px