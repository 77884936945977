@import '../../node_modules/@aurecon-creative-technologies/styleguide/src/scss/_component-vars.scss';
@import './_my_vars.scss';
@import '../../node_modules/@aurecon-creative-technologies/styleguide/src/scss/_component-styles.scss';

* {
  box-sizing: border-box;
}

span {
  font-family: Arial, sans-serif;
}

// Project / phase selection default button override
button {
  &.is-default {
    &.is-date {
      background-color: $primary--light;
      border-color: $primary--light;
      height: 40px;
    }
  }
  &.is-default.is-icon {
    &.is-date {
      background-color: $primary--light;
      border-color: $primary--light;

      &:hover:enabled,
      &:focus:enabled {
        border-color: #bdbdbd;
        background-color: #bdbdbd !important;
      }
    }
  }
}

.login,
.noaccess {
  button.is-primary {
    &.is-white {
      color: black;
      border-color: white;
      background-color: white;

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading) {
        color: white;
        border-color: #555;
        background-color: #555 !important;
      }
    }
  }

  button.is-secondary {
    &.is-white {
      color: white;
      border-color: white;
      background-color: transparent;

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading) {
        color: white;
        border-color: #999;
        background-color: #999 !important;
      }
    }
  }

  button.is-text {
    &.is-white {
      color: white;

      &:hover:enabled:not(.is-loading),
      &:focus:enabled:not(.is-loading) {
        color: #999 !important;
        border-color: transparent;
      }
    }
  }
}

button.is-text {
  &.clear-button {
    color: $aurecon--error--700;
    font-size: 14px;
    font-weight: normal;
    margin-right: 32px;

    &.inactive {
      color: $aurecon--grey--500;
    }

    &:hover:enabled:not(.is-loading),
    &:focus:enabled:not(.is-loading) {
      color: $aurecon--error--900 !important;
      border-color: transparent;
    }
  }
}

// Dropdown overrides
.dropdown-wrapper {
  .dropdown-label {
    font-weight: bold;
  }

  .dropdown-content {
    &.is-aal-scrollable {
      .dropdown-options {
        max-height: 300px;
        overflow-y: auto;
      }
    }

    &.is-aal-dropdown {
      .dropdown-selected {
        background-color: $primary--light;
        border-color: $primary--light;
      }

      .dropdown-options {
        background-color: $primary--light;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

        .dropdown-item {
          &.is-disabled {
            &:hover {
              cursor: default;
              background-color: $primary--light;
            }
          }

          &.is-selected {
            color: $primary--colour--main;
          }

          &:hover {
            background-color: rgba(25, 22, 192, 0.15);
          }
        }
      }
    }

    &.is-aal-filter {
      .dropdown-selected {
        background-color: $primary--light;
        border-color: $primary--light;

        .dropdown-down-icon {
          color: $primary--dark;
        }

        span {
          font-size: 14px;
          top: 2px;
        }

        &:hover,
        &.is-open {
          background-color: $primary--dark;

          .dropdown-down-icon {
            color: $primary--light;
          }
          span {
            color: white;
          }
        }
      }

      .dropdown-options {
        border: none;
        background-color: $primary--light;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
        max-height: 200px;
        overflow-y: auto;

        .dropdown-item {
          span {
            font-size: 14px;
          }

          &.is-disabled {
            &:hover {
              cursor: default;
              background-color: $primary--light;
            }
          }

          &:hover {
            background-color: rgba(25, 22, 192, 0.15);
          }
        }
      }
    }
  }
}

// Overflow menu overrides
.overflow-menu-wrapper {
  &.is-aal-overflow {
    .overflow-menu-options {
      background-color: $primary--light;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);

      &.is-arrow {
        .overflow-menu-arrow {
          border-top: 6px solid $primary--light;
          border-right: 6px solid $primary--light;
        }
      }

      .overflow-menu-item {
        &:hover {
          background-color: rgba(25, 22, 192, 0.15);
        }
      }
    }
  }
}

// Drawer overrides
.drawer-wrapper {
  .drawer-contents {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

// Date picker overrides
.date-picker-wrapper {
  .rdrDayNumber {
    font-family: Arial, Helvetica, sans-serif;
  }

  .rdrDateDisplayWrapper {
    background-color: white;

    .rdrDateDisplay {
      .rdrDateInput:first-child {
        border-radius: 0;
        border-color: $primary--colour--main;
        background-color: $primary--colour--main;

        input {
          color: white;
        }
      }

      .rdrDateInput:last-child {
        border-radius: 0;
        border-color: $primary--colour--main;
        background-color: white;

        input {
          color: $primary--colour--main;
        }
      }
    }
  }

  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrDayInPreview,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrInRange,
  .rdrDayEndOfWeek,
  .rdrDayEndOfMonth,
  .rdrDayWeekend,
  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge,
  .rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfWeek .rdrEndEdge,
  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge,
  .rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfWeek .rdrStartEdge,
  .rdrDay .rdrDayWeekend .rdrDayEndOfWeek .rdrDayHovered,
  .rdrDay.rdrDayWeekend .rdrDayStartOfWeek .rdrDayHovered,
  .rdrDay .rdrDayWeekend .rdrDayEndOfWeek .rdrDayActive,
  .rdrDay .rdrDayWeekend .rdrDayStartOfWeek .rdrDayActive {
    border-radius: 0 !important;
  }

  .rdrDayStartPreview,
  .rdrDayEndPreview,
  .rdrDayInPreview {
    background-color: rgba($primary--colour--main, 0.1);
  }

  .rdrDayToday .rdrDayNumber span:after {
    background-color: $primary--colour--main;
  }

  .rdrMonthAndYearWrapper {
    > button.rdrNextPrevButton.rdrPprevButton {
      border-radius: 0;
      background-color: $secondary--colour--main;

      &:hover {
        background-color: $secondary--colour--main--dark;

        i {
          border-color: transparent white transparent transparent;
        }
      }
    }

    > button.rdrNextPrevButton.rdrNextButton {
      border-radius: 0;
      background-color: $secondary--colour--main;

      &:hover {
        background-color: $secondary--colour--main--dark;

        i {
          border-color: transparent transparent transparent white;
        }
      }
    }

    .rdrMonthPicker,
    .rdrYearPicker {
      border-radius: 0;

      &:hover {
        background-color: $secondary--colour--main--dark;

        select {
          color: white;
          padding: 5px 30px 5px 10px;
        }
      }
    }
  }
}

// Modal Overrides
.modal-wrapper {
  .modal {
    h1 {
      margin-top: 0px;
    }

    .modal-actions button {
      margin-right: 20px;
    }
  }

  &.is-cookie-modal,
  &.is-terms-modal {
    .modal-overlay {
      z-index: 300;
    }

    .modal {
      position: absolute;
      bottom: 200px;
      width: 800px;

      p,
      a {
        font-size: 14px;
        color: $primary--text--colour;
      }
    }
  }
  &.is-cookie-modal {
    &.no-overlay {
      left: calc(50vw - 410px);
      top: auto;
      bottom: 80px;
      z-index: 1000;
      width: 820px;
      height: 200px;

      .modal {
        position: relative;
        bottom: auto;
        max-width: 820px;
      }
    }

    .modal {
      bottom: 100px;
    }
  }
  &.is-bottom {
    pointer-events: none;
    position: sticky;
    overflow: initial;
    bottom: 0px;

    .modal-overlay {
      background-color: transparent;
      display: none;
    }

    .modal {
      position: static;
      pointer-events: auto;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      max-width: 100%;
    }
  }
}

// Filter Pills
.filter-pills.pill-wrapper {
  color: $primary--colour--main;
}

// Switcher overrides
.switcher-wrapper.is-default.design-options {
  .switcher-item {
    padding-left: 24px;
    padding-right: 24px;

    &.is-selected {
      background-color: $secondary--colour--background;
      border-color: $secondary--colour--background;
    }

    &:hover:not(.is-selected) {
      background-color: $secondary--colour--main--dark;
    }
  }
}

// Toggle
.with-ellipsis {
  min-width: 0;

  .toggle-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}

ul li {
  font-family: Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

// Drawer
.drawer-wrapper {
  &.main-drawer {
    z-index: 200;
  }
}

// Flex break
.flex-break {
  height: 0;
  flex-basis: 100%;
}

button.button.delete-button,
.delete-modal button.button.is-primary {
  background-color: $aurecon--error--800;
  border: 1px $aurecon--error--800 solid;
  margin: 20px 0;

  &:hover:enabled:not(.is-loading),
  &:focus:enabled:not(.is-loading) {
    background-color: $aurecon--error--900 !important;
    border: 1px $aurecon--error--900 solid !important;
  }
}

// Status
.status-wrapper.status-xsmall {
  .status-label {
    font-size: 14px;
  }
}

//Archive comment modal
.archiveModalWrapper {
  .modal {
    min-width: 370px !important;
    width: 380px !important;
    height: 275px;
  }
}
