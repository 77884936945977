@import ./index

.commentTile
  width: 100%
  padding: 20px
  background-color: $aurecon--warmgrey--050
  cursor: pointer
  transition: all 400ms ease

  .commentTileArchive
    display: flex
    justify-content: flex-end

  &:hover
    background-color: white
    margin-top: -2px
    margin-left: -2px
    box-shadow: 0px 2px 4px rgba(18, 18, 18, 0.1)

  h2
    font-size: 18px
    line-height: 24px
    margin: 20px 0 10px

.dateIcon
  display: flex
  align-items: center
  font-size: 14px
  margin: 0

  span:first-of-type
    margin-right: auto

  i:global(.icon-wrapper)
    margin-right: 10px
    font-size: 20px
    top: 0

    &.attach
      width: 6px
      font-size: 16px

  > svg
    margin-right: 4px

    > path
      fill: #121212

.title
  font-size: 14px
  max-width: 100%
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden

.smallText
  font-size: 12px
  margin: 10px 0

  &:last-child
    margin-bottom: 0

.archived
  background-color: rgba($aurecon--warmgrey--050, 0.35)
  
  .commentTileContent 
    opacity: 35%
