.loader
  width: 100%
  height: calc(100vh - 72px)
  min-height: 200px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  
.loginPage
  width: 100vw
  height: 100vh
  background-image: url("../assets/page-background.png")
  background-position: top left
  background-repeat: repeat
  background-size: auto
  background-color: #52534B
  color: white
  font-family: Arial, Helvetica, sans-serif

  .loginContainer
    position: relative
    display: flex
    flex-direction: column
    height: 100vh
    margin: 0 32px 0 32px
    overflow: auto

    @media screen and (min-width: 576px)
      margin: 0 0 0 32px

    @media screen and (min-width: 768px)
      margin: 0 0 0 72px

    @media screen and (min-width: 992px)
      margin: 0 0 0 144px

  .leftGradient
    position: absolute
    top: 0
    bottom: 0
    width: 100%
    background: rgba(0,0,0, 0.5)
    background: linear-gradient(90deg, rgba(18, 18, 18, 0.5) 5.73%, rgba(18, 18, 18, 0.3) 58.33%, rgba(18, 18, 18, 0) 100%)

  h1
    font-weight: 400
    font-size: 72px
    line-height: 0em

  h2
    font-weight: normal
    font-size: 30px
    line-height: 1em

  p
    margin-bottom: 3em

  button
    margin-right: 20px

  .header
    padding-top: 72px
    flex: 1

  .content
    flex: 1
    width: 100%

    @media screen and (min-width: 576px)
      width: 400px

  .footer
    padding-bottom: 72px
    flex: 0