.wrapper
  width: 100%
  height: calc(100vh - 72px)
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  h1
    margin-bottom: 40px
    text-align: center

  button
    margin-right: 0
    align-self: flex-end
