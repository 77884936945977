.container
  margin-top: 80px
  h1
    width: 100%
    text-align: center
    margin-top: 60px
    margin-bottom: 20px

  h3
    width: 100%
    text-align: center

.works
  display: flex
  flex-direction: column
  align-items: center
  margin: 0 30px
  
  svg
    margin-top: 20px
  
  h3
    text-align: left

.find-out
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 40px
  margin-bottom: 60px

  button
    width: 300px
  