@import ./index

$margin-bottom: 40px

.wrapper
  width: 100%
  margin-top: 150px
  display: flex
  flex-direction: column
  align-items: center

  h1
    text-align: center
    margin-bottom: $margin-bottom

  div:global(.field-wrapper)
    margin-bottom: $margin-bottom
  
  p
    color: $aurecon--warmgrey--500

.change-password-wrapper  
  width: 100%
  button
    float: left
    margin-bottom: $margin-bottom


.buttons
  width: 100%
  display: flex
  flex-direction: row
  gap: 20px
