@import ./index

.sectionVideoWrapper
  position: absolute
  bottom: 100px
  right: 100px
  background-color: black
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3)

.sizeIcon
  position: absolute
  top: 0
  right: 0
