@import './index.scss'

.sortButton
  margin-left: 8px

.sortLabel
  align-self: center
  font-weight: bold

.searchInput
  input
    border: none !important
    background: #F2F2ED
    padding-right: 0
